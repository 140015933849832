import React, { Component } from "react";


class HeroOlive2 extends Component {
    render(){
        return(
            <div className={`slider-area bg-color ${this.props.horizontal} ${this.props.bgshape}`} id="home"  style={{background:'#2d3e50'}}>
                <div className="container h-100">
                    <div className="row">
                        <div className="col-lg-7 h-100">
                            <div className="banner-text">
                                <div className="banner-table-cell">
                                    <h1>AWESOME <br /> <span className="theme-color">Cosmos-diagram</span> WINDOWS OS APP.</h1>
                                    <p><span className="theme-color">Официальный сайт программы для создания блок-схем Cosmos-diagram</span>
                                        <br></br>Простой и в то же время функциональный редактор блок-схем по привлекательной <a className="button-nav underline theme-color" href="/#pricing">цене</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default HeroOlive2;








