import React,  { Component } from "react";

import vars from './vars';

class Pricing extends Component{
    render(){
        return(
            <div className={`pricing-table-area pt--40 pt_sm--100 ${this.props.horizontalpricing}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center mb--40" id="pricing">
                                <h2>ЦЕНОВЫЕ <span className="theme-color">ПЛАНЫ</span></h2>
                                <img className="image-1" src={require('../assets/images/app/title-icon.webp')} alt="Cosmos-diagram Logo"/>
                                <img className="image-2" src={require('../assets/images/app/title-icon-2.webp')} alt="Cosmos-diagram Logo"/>
                                <img className="image-3" src={require('../assets/images/app/title-icon-3.webp')} alt="Cosmos-diagram Logo"/>
                                <p><span className="text-red"></span>При первой <a className="underline theme-color"  href={vars.protocol + "auth."+ vars.baseWebSite + "/"} target="_blank">регистрации</a> Вы получите аккаунт активированный на 14 дней.</p>                               
                                <p>Выберите выгодную Вам покупку программы</p>
                            </div>
                        </div>
                    </div>
                    <div className="row mt--30">

                        {/* Start Single Pricing */}
                        <div className="col-lg-4 col-md-6 col-12 pricing-column mt--40">
                            <div className="single-price-package">
                                <div className="price-title">
                                    <h3>Навсегда</h3>
                                    <div className="price">
                                        <h4><span className="text-large">10 000</span> <span className="text-top">руб.</span></h4>
                                        <p><span className="text-bottom">/навсегда</span></p>
                                    </div>
                                </div>
                                <div className="price-list">
                                    <ul>
                                        <li>Полный функционал</li>
                                        <li>Все обновления</li>
                                        <li>Предложение ограничено</li>
                                        <li>Есле не успеете, то будет только по подписке</li>
                                    </ul>
                                    <div className="price-btn">
                                        <a href="http://allsoft.ru/basket_add_direct.php?id=1407652&price_group_id=4726741" target={"_blank"} className="button" type="button">Купить руб. РФ</a><br/>
                                        <a href="https://allsoft.by/software/graphics-design/different-utilities/Cosmos-diagram/" target={"_blank"} className="button" type="button">Купить руб. Бел.</a>                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Single Pricing */}

                        {/* Start Single Pricing */}
                        <div className="col-lg-4 col-md-6 col-12 pricing-column mt--40">
                            <div className="single-price-package list-large">
                                <div className="price-title">
                                    <h3>Подписка</h3>
                                    <div className="price">
                                        <h4><span className="text-large">6000</span><span className="text-top">руб.</span></h4>
                                        <p><span className="text-bottom">/год</span></p>
                                    </div>
                                </div>
                                <div className="price-list">
                                    <ul>
                                        <li>Полный функционал</li>
                                        <li>Все обновления</li>
                                        <li>Будет доступно позже</li>
                                    </ul>
                                    <div className="price-btn">
                                        <button className="button" type="button" disabled="disabled">Купить</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Single Pricing */}

                        {/* Start Single Pricing */}
                        <div className="col-lg-4 col-md-6 col-12 pricing-column mt--40">
                            <div className="single-price-package list-large">
                                <div className="price-title">
                                    <h3>Подписка с поддержкой</h3>
                                    <div className="price">
                                        <h4><span className="text-large">10 000</span><span className="text-top">руб.</span></h4>
                                        <p><span className="text-bottom">/год</span></p>
                                    </div>
                                </div>
                                <div className="price-list">
                                    <ul>
                                        <li>Полный функционал</li>
                                        <li>Все обновления</li>
                                        <li>Поддержка от разработчиков</li>
                                        <li>Будет доступно позже</li>
                                    </ul>
                                    <div className="price-btn">
                                        <button className="button" type="button" disabled="disabled">Купить</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Single Pricing */}
                    </div>
                </div>
            </div>
        )
    }
}

export default Pricing;













