import React ,  { Component } from "react";

class About extends Component{
    render(){
        return(
            <div className={`app-about ${this.props.horizontalabout}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center mb--40" id="about">
                                <h2>О <span className="theme-color">ПРОГРАММЕ</span></h2>
                                <img className="image-1" src={require('../assets/images/app/title-icon.webp')} alt="Cosmos-diagram Logo"/>
                                <img className="image-2" src={require('../assets/images/app/title-icon-2.webp')} alt="Cosmos-diagram Logo"/>
                                <img className="image-3" src={require('../assets/images/app/title-icon-3.webp')} alt="Cosmos-diagram Logo"/>
                                <p>Cosmos-diagram - простой и удобный редактор блок-схем.<br></br>Нестандартная привязка элементов, сетка и черчение с зажатой клавишей Shift - удовлетворит даже перфекционистов.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="about-thumbnail">
                                <a rel="nofollow" href={require('../assets/images/app/screenshot-01.webp')} data-fancybox="screenshot-program2" data-caption='Основная панель'><img className="image" src={require('../assets/images/app/screenshot-01.webp')} alt="Cosmos-diagram"/></a>
                                {/* <img className="image-1" src={require('../assets/images/app/mobile.webp')} alt="App Landing"/>
                                <img className="image-2" src={require('../assets/images/app/mobile-2.webp')} alt="App Landing"/>
                                <img className="image-3" src={require('../assets/images/app/mobile-3.webp')} alt="App Landing"/> */}
                            </div>
                        </div>
                        <div className="col-lg-6 mt--14 mt-md-40 mt-sm-40">
                            <div className="about-content">
                                <h2 className="title">BEST <span className="theme-color">РЕДАКТОР БЛОК-СХЕМ</span></h2>
                                <p>Cosmos-diagram - это простое и интуитивно понятное решение для создания блок-схем. 
                                    Широкие настройки отображения элементов, удобный и красивый интерфейс и отсутствие лишнего сделают Вашу работу приятной. 
                                    Блок-схемы сохраняются в обычные xml-файлы, они лёгкие и вы можете без проблем обмениваться ими с коллегами. 
                                    При необходимости можно сохранить блок-схему или её часть в растровый формат изображения png.</p>
                                <p>Редактор блок-схем Cosmos-diagram - это полностью российская разработка.</p>
                                <div className="about-buttons">
                                    <a className="button-nav button-default button-olive button-border" href="/#download" role="button">Download</a>
                                    <a className="button-nav button-default button-border" href="/#features" role="button">Продолжить изучение</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default About;











