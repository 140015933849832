import React , { Component } from "react";

import vars from './vars';
// import {showSuccess, showError} from './noty_func'

class Footer extends Component {
    uploadAction_message(){
        const inputLogin = document.querySelector('#message [name=login]');
        const inputEmail = document.querySelector('#message [name=email]');
        const inputMessage = document.querySelector('#message [name=message]');

        fetch(vars.authDodmain + "api/Message", {
            mode: 'cors',
            method: "POST",  
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },          
            body: JSON.stringify({WasRead: false, UserName:inputLogin.value, Email : inputEmail.value, Body : inputMessage.value})
        }).then(function(res) {
            if (res.ok) {
                var data = new FormData(document.getElementById('message'));     
                // var login = document.getElementById('message').querySelector('input[name="login"]').value;
                // data.append("email", login);
                // var email = document.getElementById('message').querySelector('input[name="email"]').value;
                // data.append("email", email);  
                // var message = document.getElementById('message').querySelector('textarea[name="message"]').value;
                // data.append("message", message);  

                data.append("subdomain", vars.subdomain);

                fetch(vars.emailDomain + "api/message.php", {
                    mode: 'cors',
                    method: "POST",
                    body: data
                }).then(function (res) {
                    if (res.ok) {
                    global.showSuccess("Success! You have successfully sent us a message.");
                    } else if (res.status === 401) {
                        global.showError("Oops! ");
                    }else if (res.status === 200) {
                        global.showSuccess("Perfect!");
                    }
                }, function (e) {
                    global.showError("Error submitting form! " + e.message);
                });   
        } else if (res.status === 401) {
            global.showError("Oops! 1");
        }else if (res.status === 200) {
            global.showSuccess("Perfect! 1");
        }            
        }, function (e) {
            global.showError("Error submitting form! " + e.message);
        });   
    }
    uploadAction_newsletter() {
        
        
        // var email = document.querySelector('#newsletter input[name="email"]').value;
        // data.append("email", email);  

        

        const inputEmail = document.querySelector('#newsletter [name=email]');

        fetch(vars.authDodmain + "api/Sending", {
            mode: 'cors',
            method: "POST",  
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },          
            body: JSON.stringify({Email : inputEmail.value, IsAgree : true})
        }).then(function(res) {
            if (res.ok) {
                var data = new FormData(document.getElementById('newsletter'));
                data.append("subdomain", vars.subdomain);

                fetch(vars.emailDomain + "api/newsletter.php", {
                    mode: 'cors',
                    method: "POST",
                    body: data
                }).then(function(res) {
                    if (res.ok) {
                        //showSuccess("Perfect! You have subscribed to the newsletter.");
                        return res.json();
                    } else if (res.status === 401) {
                        global.showError("Oops! 2");
                    }else if (res.status === 200) {
                        global.showSuccess("Perfect! 2");
                    }            
                    })
                .then(function(json) {
                    global.showSuccess("Success! You have subscribed to the newsletter. Email: " + json.email);           
                }, function (e) {
                    global.showError("Error submitting form! " + e.message);
                });
            } else if (res.status === 401) {
                global.showError("Oops! 1");
            }else if (res.status === 200) {
                global.showSuccess("Perfect! 1");
            }            
            }, function (e) {
                global.showError("Error submitting form! " + e.message);
            });    
    }

    render(){
        return(
            <footer>
                <div className={`footer-area ${this.props.horizontal}`}>
                    <div className="footer-bg"/>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--40" id="support">
                                    <h2 className="text-white">СВЯЖИТЕСЬ <span className="theme-color">С НАМИ</span></h2>
                                    <img className="image-1" src={require('../assets/images/app/title-icon.webp')} alt="Cosmos-diagram Logo"/>
                                    <img className="image-2" src={require('../assets/images/app/title-icon-2.webp')} alt="Cosmos-diagram Logo"/>
                                    <img className="image-3" src={require('../assets/images/app/title-icon-3.webp')} alt="Cosmos-diagram Logo"/>
                                    <p className="text-white">Свяжитесь с нами и мы ответим на все интересующие Вас вопросы.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-5 col-xl-4 offset-xl-1">
                                <div className="contact-inner">
                                    <h3 className="title">Задайте вопрос!</h3>
                                    <form className="contact-form" action="/" method="post" id="message">
                                        <input type="hidden" name="message-type" value="Форма обратной связи"/>
                                        <div className="input-box">
                                            <input type="text" name="login" placeholder="Логин или Ваше имя*" required/>
                                        </div>

                                        <div className="input-box">
                                            <input type="text" name="email" placeholder="Email*" required/>
                                        </div>

                                        <div className="input-box">
                                            <textarea name="message" placeholder="Сообщение"></textarea>
                                        </div>

                                        <div className="input-box">
                                            <button className="submite-button" type="button" name="send_message" id="send_message" onClick={this.uploadAction_message.bind(this)}>Отправить</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-lg-5 offset-lg-2 col-xl-4 offset-xl-2 mt_md--40 mt_sm--40">
                                <div className="contact-inner">
                                    <h3 className="title">Наши контакты</h3>
                                    <div className="conatct-info">
                                        {/* <div className="single-contact-info">
                                            <div className="contact-icon">
                                                <i className="zmdi zmdi-phone"></i>
                                            </div>
                                            <div className="contact-text">
                                                <span><a href="tel:+79299937558">+7 (929) 993-75-58</a><br />
                                                <a href="tel:+79255337506">+7 (925) 533-75-06</a></span>
                                            </div>
                                        </div> */}
                                        <div className="single-contact-info">
                                            <div className="contact-icon">
                                                <i className="zmdi zmdi-vk"></i>
                                            </div>
                                            <div className="contact-text">
                                                <span><a href="https://vk.com/usversoft_com" target="_blank">@usversoft_com</a></span>
                                            </div>
                                        </div>
                                        <div className="single-contact-info">
                                            <div className="contact-icon">
                                                <div style={{marginTop:['12px'], marginLeft:['6px']}}>
                                            <svg width="80%" height="80%" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                                <path d="m20.665 3.717-17.73 6.837c-1.21.486-1.203 1.161-.222 1.462l4.552 1.42 10.532-6.645c.498-.303.953-.14.579.192l-8.533 7.701h-.002l.002.001-.314 4.692c.46 0 .663-.211.921-.46l2.211-2.15 4.599 3.397c.848.467 1.457.227 1.668-.785l3.019-14.228c.309-1.239-.473-1.8-1.282-1.434z"/>
                                            </svg>
                                            </div>
                                            </div>
                                            <div className="contact-text">
                                                <span><a href="https://t.me/usversoft_com" target="_blank">@usversoft_com</a></span>
                                            </div>
                                        </div>
                                        <div className="single-contact-info">
                                            <div className="contact-icon">
                                                <i className="zmdi zmdi-globe-alt"></i>
                                            </div>
                                            <div className="contact-text">
                                                <span><a href={"mailto:info@usversoft.com"} target="_blank">info@usversoft.com</a><br />
                                                <a href={vars.protocol + vars.webSite + "/"}>{vars.protocol + vars.webSite}/</a></span>
                                            </div>
                                        </div>
                                        {/* <div className="single-contact-info">
                                            <div className="contact-icon">
                                                <i className="zmdi zmdi-pin"></i>
                                            </div>
                                            <address className="contact-text">
                                                <span>Москва<br /> ул. Сходненская, 14</span>
                                            </address>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2 col-xl-6 offset-xl-3">
                                <div className="newsletter text-center">
                                    <h3 className="text-white title">ПОДПИШИТЕСЬ НА НАШУ РАССЫЛКУ</h3>
                                    <p className="text-white">Подпишетесь на нашу рассылку и Вы будете в курсе<br />
                                        всех последних изменений и акций.</p>
                                    <form action="" method="post" id="newsletter">
                                        <div className="newsletter-content">
                                            <input type="email" name="email" placeholder="Введите Ваш Email адрес*" required/>
                                            <button type="button" className="button" name="send_newsletter" id="send_newsletter" onClick={this.uploadAction_newsletter.bind(this)}><span>Подписаться</span></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2 col-xl-4 offset-xl-4">
                                <div className="footer-links text-center">
                                    <a href="https://vk.com/usversoft_com" target={"_blank"} aria-label="ВКонтанке"><i className="zmdi zmdi-vk"></i></a>
                                    <a href="https://t.me/usversoft_com" target={"_blank"} aria-label="Telegram">
                                        <svg fill="#fff"style={{marginTop:['4px'], marginLeft:['4px']}} width="30px" height="30px" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                            <path d="m20.665 3.717-17.73 6.837c-1.21.486-1.203 1.161-.222 1.462l4.552 1.42 10.532-6.645c.498-.303.953-.14.579.192l-8.533 7.701h-.002l.002.001-.314 4.692c.46 0 .663-.211.921-.46l2.211-2.15 4.599 3.397c.848.467 1.457.227 1.668-.785l3.019-14.228c.309-1.239-.473-1.8-1.282-1.434z"/>
                                        </svg>
                                    </a>
                                    
                                    {/* <a href="/"><i className="zmdi zmdi-facebook"></i></a> */}
                                    {/* <a href="/"><i className="zmdi zmdi-twitter"></i></a> */}
                                    {/* <a href="/"><i className="zmdi zmdi-google"></i></a> */}
                                    {/* <a href="/"><i className="zmdi zmdi-linkedin"></i></a> */}
                                    {/* <a href="/"><i className="zmdi zmdi-pinterest"></i></a> */}
                                    <a href="https://www.youtube.com/@usversoft_com" target={"_blank"} aria-label="Youtube"><i className="zmdi zmdi-youtube"></i></a>
                                </div>
                                <div className="footer-text text-center">
                                    <span><time dateTime={new Date().getFullYear()}>Copyright © {new Date().getFullYear()} <a href={vars.protocol + vars.webSite + "/"}>Cosmos-diagram</a></time></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tap-top">
                    <div>
                        <i className="zmdi zmdi-long-arrow-up"></i>
                    </div>
                </div>               
            </footer>
        )
    }
}
export default Footer







