import React ,  { Component } from "react";



class Feature extends Component{
    render(){
       let data = [
           
        {
            icon: 'zmdi zmdi-collection-text',
            title: 'Неограниченные возможности',
            desc: 'Вы можете создавать блок-схемы и сохранять их в файлы, обменеваться ими и экспортировать в растовое изображение.'
        },

        {
            icon: 'zmdi zmdi-sun',
            title: 'Нестандартный подход',
            desc: 'Нестандартный подход к формировани блок-схем, сделает Вашу задачу простой, красивой и удовлетворит перфекционистов.'
        },

        {
            icon: 'zmdi zmdi-brush',
            title: 'Современный дизайн',
            desc: 'Дизайн программы разработан по всем современным стандартам. Простота и удобство использования помогут Вам быстро освоить редактор.'
        },

        {
            icon: 'zmdi zmdi-desktop-mac',
            title: 'Уникальный дизайн',
            desc: 'Вы не видели ничего подобного с точки зрения оформления программы редактора блок-схем.'
        },

        {
            icon: 'zmdi zmdi-language-html5',
            title: 'Чистый код',
            desc: 'Программа написана с ноля, без использования сторонних плагинов.'
        }
       ] 

       let DataList = data.map((val , i) => {
           return(
               /* Start Single Feature */
               <div className="feature" key={i}>
                   <div className="feature-icon">
                       <i className={`${val.icon}`}/>
                   </div>
                   <div className="content">
                       <h3 className="title">{val.title}</h3>
                       <p className="desc">{val.desc}</p>
                   </div>
               </div>
               /* End Single Feature */
           )
       })

       return(
           <div className= {`feature-area feature-bg-image pb--50 ${this.props.horizontalfeature}`}>
               <div className="container">
                   <div className="row">
                       <div className="col-lg-12">
                            <div className="section-title text-center mb--40" id="features">
                                <h2>НЕСТАНДАРТНЫЕ <span className="theme-color">ВОЗМОЖНОСТИ</span></h2>
                                <img className="image-1" src={require('../assets/images/app/title-icon.webp')} alt="Cosmos-diagram Logo"/>
                                <img className="image-2" src={require('../assets/images/app/title-icon-2.webp')} alt="Cosmos-diagram Logo"/>
                                <img className="image-3" src={require('../assets/images/app/title-icon-3.webp')} alt="Cosmos-diagram Logo"/>
                                <p>Вы сразу получите полнофункциональный редактор без каких либо ограничений со стороны разработчиков</p>
                            </div>
                       </div>
                   </div>
                   <div className="row mt--30">
                        <div className="col-lg-5">
                            <a rel="nofollow" href={require('../assets/images/app/screenshot-01.webp')} data-fancybox="screenshot-program" data-caption='Основная панель'><img className="image-program" src={require('../assets/images/app/program.webp')} alt="Cosmos-diagram"/></a>
                        </div>
                        <div className="col-lg-7">
                            <div className="feature-list">
                                {DataList}
                            </div>
                       </div>
                   </div>
               </div>
           </div>
        )
    }
}

export default Feature;
