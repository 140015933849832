import React , { Component } from "react";

import vars from './vars';

class Download extends Component{
    render(){
        return(
            <div className={`download-area ${this.props.horizontal}`}>
                <div className="bg-overlay"/>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center mb--55 pt--50" id="download">  
                                <h2 className="text-white">DOWNLOAD НАШЕ <span className="theme-color">LATESTAPP</span></h2>
                                <img src={require('../assets/images/app/title-icon-4.webp')} alt="Cosmos-diagram Logo"/>
                                <p className="text-white"><span className="text-red"></span>При первой <a className="underline theme-color"  href={vars.protocol + "auth."+ vars.baseWebSite + "/"} target="_blank">регистрации</a> Вы получите аккаунт активированный на 14 дней.</p>
                                <img src={require('../assets/images/app/title-icon-4.webp')} alt="Cosmos-diagram Logo"/>
                                <p className="text-white">Скачайте последнюю версию программы Cosmos-diagram v1.1.11.0<br></br>Если у Вас ещё нет аккаунта, то пройдите простую регистрацию при первом запуске приложения. Не забудьте подтвердить e-mail, иначе аккаунт не будет активирован.</p>
                                <p><a href="/history" target={"_blank"} className={"underline theme-color"}>История версий</a></p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="download-buttons pb--50">
                                {/* <button type="submit" className="download-btn">
                                    <i className="zmdi zmdi-apple"></i>
                                    <span>Available on the <span className="large-text">App Store</span> </span>
                                </button> */}
                                {/* <button type="submit" className="download-btn">
                                    <i className="zmdi zmdi-google-play"></i>
                                    <span>Available on the <span className="large-text">Play Store</span> </span>
                                </button> */}
                                <a href="/downloads/cosmos-diagram-v1.1.11.0-setup.zip" target="_blank" className="download-btn">
                                    <i className="zmdi zmdi-windows"></i>
                                    <span>Скачать для <span className="large-text">Windows</span> </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Download;







