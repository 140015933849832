import React, { Component } from "react";
import liteYoutube from '../../node_modules/lite-youtube-embed/src/lite-yt-embed';

class Video extends Component { 
    render(){
        return (
            <div className="screenshot-area pt--120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center mb--80" id="video">
                                <h2>APP <span className="theme-color">ВИДЕООБУЧЕНИЕ</span></h2>
                                <img className="image-1" src={require('../assets/images/app/title-icon.webp')} alt="Cosmos-diagram Logo"/>
                                <img className="image-2" src={require('../assets/images/app/title-icon-2.webp')} alt="Cosmos-diagram Logo"/>
                                <img className="image-3" src={require('../assets/images/app/title-icon-3.webp')} alt="Cosmos-diagram Logo"/>
                                <p>Видеообучение по программе,<br></br>показывающее возможноти Cosmos-diagram.</p>
                                <p><a href="https://www.youtube.com/@usversoft-com" target={"_blank"} className={"underline theme-color"}><i className="zmdi zmdi-youtube"></i> Всё Видеообучение</a></p>
                            </div>
                        </div>
                        <div className="col-lg-10 offset-lg-1">
                            <div className="row-video">
                            <lite-youtube videoid="IzVJMCyL7hQ" title="Play Video Cosmos-diagram v1.0.0.0 help" style={{ backgroundImage: `url(${require("../assets/images/app/screenshot-01.webp")})` }}>
                            </lite-youtube>
                                {/* <iframe src="https://www.youtube.com/embed/IzVJMCyL7hQ" title="Cosmos-diagram v1.0.0.0 video help" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> */}
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
        )
    }
}

export default Video;










