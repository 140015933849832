import React, {Component, Fragment} from "react";
import HeroOlive2 from '../components/HeroOlive2';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import HistoryVersion from '../components/HistoryVersion';
class History extends Component {
    render() {

        return (
            <Fragment>
                {/* Header Navbar */}
                <Navbar/>

                {/* Slider */}
                <HeroOlive2 bgshape="bg-shape" horizontal=""/>

                {/* History */}
                <HistoryVersion bgshape="bg-shape" horizontal=""/>                

                {/* Footer */}
                <Footer horizontal="vertical-footer"/>
            </Fragment>
        )
    }
}

export default History;

