import React, { Component } from "react";

import vars from './vars';

class HeroOlive extends Component {
    render(){
        return(
            <div className={`slider-area bg-color ${this.props.horizontal} ${this.props.bgshape}`} id="home"  style={{background:'#2d3e50'}}>
                <div className="container h-100">
                    <div className="row">
                        <div className="col-lg-7 h-100">
                            <div className="banner-text">
                                <div className="banner-table-cell">
                                    <h1>AWESOME <br /> <span className="theme-color">Cosmos-diagram</span> WINDOWS OS APP.</h1>
                                    <p><span className="theme-color">Официальный сайт программы для создания блок-схем Cosmos-diagram</span>
                                        <br></br>Простой и в то же время функциональный редактор блок-схем по привлекательной <a className="button-nav underline theme-color" href="/#pricing">цене</a></p>
                                    <p><span className="text-red"></span>При первой <a className="underline theme-color" href={vars.protocol + "auth."+ vars.baseWebSite + "/"} target="_blank">регистрации</a> Вы получите аккаунт активированный на 14 дней.</p>                               
                                    <div className="banner-buttons">
                                        <a className="button-nav button-default button-olive" href="/#download" role="button">Download</a>
                                        <a className="button-nav button-default button-white" href="/#about" role="button">Узнать подробнее</a>
                                    </div>
                                </div>
                            </div>

                            <div className="banner-apps">
                                {/* Start Sinhle app */}
                                <div className="single-app">                                    
                                    <div className="single-app-table-cell">
                                        <a className="button-nav margin-0" href="/#screenshots" aria-label="Перейти к скриншотам">
                                            <i className="zmdi zmdi-collection-image"></i>
                                        </a>
                                        <h2>Screenshots</h2>
                                        {/* <h3></h3> */}
                                    </div>
                                </div>

                                {/* Start Single app */}
                                <div className="single-app">
                                    <div className="single-app-table-cell">
                                        <a className="button-nav margin-0" href="/#video" aria-label="Перейти к видеообучению">
                                            <i className="zmdi zmdi-youtube-play"/>
                                        </a>
                                        <h2>Video</h2>
                                        {/* <h3></h3> */}
                                    </div>
                                </div>

                                {/* Start Sinhle app */}
                                <div className="single-app">
                                    <div className="single-app-table-cell">
                                        <a className="button-nav margin-0" href="/#download" aria-label="Перейти к скачиванию программы">
                                            <i className="zmdi zmdi-cloud-download"></i>
                                        </a>
                                        <h2>Download</h2>
                                        {/* <h3></h3> */}
                                    </div>
                                </div>                                
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="banner-product-image text-right">
                                <div className="image-logo-div">
                                <img className="image" src={require('../assets/images/logo/logo_sh_1.png')} alt="Cosmos-Diagram"/>
                                {/* <img className="image-1" src={require('../assets/images/app/banner-mobile.webp')} alt="App Landing"/>
                                <img className="image-2" src={require('../assets/images/app/banner-mobile-2.webp')} alt="App Landing"/>
                                <img className="image-3" src={require('../assets/images/app/banner-mobile-3.webp')} alt="App Landing"/> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default HeroOlive;








