import React ,  { Component } from "react";

import vars from './vars';

class Navbar extends Component {
    render(){
        return(
            <div className="app-header header--transparent sticker" id="main-menu">
                <div className="container v2">
                    <div className="row align-items-center">
                        <div className="col-lg-4 col-sm-5 col-5">
                            <div className="logo">
                                <a href={`${process.env.PUBLIC_URL}/`}>
                                    <img className="logo-0" src={require('../assets/images/logo/logo_long_1.png')} alt="Cosmos-diagram Logo"/>
                                    {/* <img className="logo-1" src={require('../assets/images/logo/logo.webp')} alt="Cosmos-diagram Logo"/>
                                    <img className="logo-2" src={require('../assets/images/logo/logo-2.webp')} alt="Cosmos-diagram Logo"/>
                                    <img className="logo-3" src={require('../assets/images/logo/logo-3.webp')} alt="Cosmos-diagram Logo"/> */}
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-8 d-none d-lg-block">
                            <div className="mainmenu-wrapper">
                                <nav>
                                    <ul className="main-menu">
                                        <li className="active"><a href="/#home">Home</a></li>
                                        <li><a href="/#about">О&nbsp;нас</a></li>
                                        <li><a href="/#features">Возможности</a></li>
                                        <li className="nav-download"><a className="button-nav button-default button-olive" href="/#download" role="button">Download</a></li>
                                        <li><a href="/#pricing">Цены</a></li>
                                        {/* <li><a href="/#reviews">Отзывы</a></li> */}
                                        <li><a href="/#screenshots">Скриншоты</a></li>
                                        <li><a href="/#video">Видео</a></li>
                                        <li><a href="/#support">Поддержка</a></li>
                                        <li><a href={vars.protocol + "auth."+ vars.baseWebSite + "/"} target="_blank">Login</a></li>                                        
                                    </ul>
                                </nav>
                                {/* <a className="button-nav button-default button-olive" href="#download" role="button">Download</a> */}
                            </div>
                        </div>
                        <div className="col-sm-7 col-7 d-block d-lg-none">
                            <div className="mobile-menu">
                                <nav>
                                    <ul>                              
                                        <li className="active"><a href="/#home">Home</a></li>
                                        <li><a href="/#about">О&nbsp;нас</a></li>
                                        <li><a href="/#features">Возможности</a></li>
                                        <li className="nav-download"><a className="button-nav button-default button-olive" href="/#download" role="button">Download</a></li>
                                        <li><a href="/#pricing">Цены</a></li>
                                        {/* <li><a href="/#reviews">Отзывы</a></li> */}
                                        <li><a href="/#screenshots">Скриншоты</a></li>
                                        <li><a href="/#video">Видео</a></li>
                                        <li><a href="/#support">Поддержка</a></li>
                                        <li><a href={vars.protocol + "auth."+ vars.baseWebSite + "/"} target="_blank">Login</a></li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Navbar;


