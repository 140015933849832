import React, { Component } from "react";
// import Carousel3D from "./Carousel3D";

class Screenshot extends Component {
    // state = { width: 0, height: 0, carousel3D: <Carousel3D/> };



render() {
    return (
        <div className="screenshot-area pt--120">
            {/* <span>Window size: {this.state.width} x {this.state.height}</span> */}
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title text-center mb--80" id="screenshots">
                            <h2>APP <span className="theme-color">СКРИНШОТЫ</span></h2>
                            <img className="image-1" src={require('../assets/images/app/title-icon.webp')} alt="Cosmos-diagram Logo" />
                            <img className="image-2" src={require('../assets/images/app/title-icon-2.webp')} alt="Cosmos-diagram Logo" />
                            <img className="image-3" src={require('../assets/images/app/title-icon-3.webp')} alt="Cosmos-diagram Logo" />
                            <p>Основные скриншоты программы,<br></br>показывающие возможноти Cosmos-diagram.</p>
                        </div>
                    </div>
                    <div id="screenshots-carousel3D" className="col-lg-10 offset-lg-1">
                        <div className="screenshot-carousel" id="screenshot-carousel">
                            <a rel="nofollow" href={require('../assets/images/app/screenshot-01.webp')} data-fancybox="screenshot" data-caption='Основная панель'><img className="image-1" src={require('../assets/images/app/screenshot-01-min.webp')} alt="Cosmos-diagram App Screenshot" /></a>
                            <a rel="nofollow" href={require('../assets/images/app/screenshot-02.webp')} data-fancybox="screenshot" data-caption='Панель параметров фигуры'><img className="image-1" src={require('../assets/images/app/screenshot-02-min.webp')} alt="Cosmos-diagram App Screenshot" /></a>
                            <a rel="nofollow" href={require('../assets/images/app/screenshot-03.webp')} data-fancybox="screenshot" data-caption='Панель настроек текста'><img className="image-1" src={require('../assets/images/app/screenshot-03-min.webp')} alt="Cosmos-diagram App Screenshot" /></a>
                            <a rel="nofollow" href={require('../assets/images/app/screenshot-04.webp')} data-fancybox="screenshot" data-caption='Панель стилей'><img className="image-1" src={require('../assets/images/app/screenshot-04-min.webp')} alt="Cosmos-diagram App Screenshot" /></a>
                            <a rel="nofollow" href={require('../assets/images/app/screenshot-05.webp?v=1.0.1.0')} data-fancybox="screenshot" data-caption='Панель настроек холста'><img className="image-1" src={require('../assets/images/app/screenshot-05-min.webp?v=1.0.1.0')} alt="Cosmos-diagram App Screenshot" /></a>
                            <a rel="nofollow" href={require('../assets/images/app/screenshot-06.webp')} data-fancybox="screenshot" data-caption='Панель настроек'><img className="image-1" src={require('../assets/images/app/screenshot-06-min.webp')} alt="Cosmos-diagram App Screenshot" /></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

// getRender = () => {
//     return <div className="screenshot-carousel" id="screenshot-carousel" data-carousel-3d>
//     <a rel="nofollow" href={require('../assets/images/app/screenshot-01.webp')} data-fancybox="screenshot" data-caption='Основная панель'><img className="image-1" src={require('../assets/images/app/screenshot-01.webp')} alt="Cosmos-diagram App Screenshot" /></a>
//     <a rel="nofollow" href={require('../assets/images/app/screenshot-02.webp')} data-fancybox="screenshot" data-caption='Панель параметров фигуры'><img className="image-1" src={require('../assets/images/app/screenshot-02.webp')} alt="Cosmos-diagram App Screenshot" /></a>
//     <a rel="nofollow" href={require('../assets/images/app/screenshot-03.webp')} data-fancybox="screenshot" data-caption='Панель настроек текста'><img className="image-1" src={require('../assets/images/app/screenshot-03.webp')} alt="Cosmos-diagram App Screenshot" /></a>
//     <a rel="nofollow" href={require('../assets/images/app/screenshot-04.webp')} data-fancybox="screenshot" data-caption='Панель стилей'><img className="image-1" src={require('../assets/images/app/screenshot-04.webp')} alt="Cosmos-diagram App Screenshot" /></a>
//     <a rel="nofollow" href={require('../assets/images/app/screenshot-05.webp')} data-fancybox="screenshot" data-caption='Панель настроек холста'><img className="image-1" src={require('../assets/images/app/screenshot-05.webp')} alt="Cosmos-diagram App Screenshot" /></a>
//     <a rel="nofollow" href={require('../assets/images/app/screenshot-06.webp')} data-fancybox="screenshot" data-caption='Панель настроек'><img className="image-1" src={require('../assets/images/app/screenshot-06.webp')} alt="Cosmos-diagram App Screenshot" /></a>
// </div>;
// };

//     updateDimensions = () => {   
//         //carousel3D.call(this.render) ;    
//         //this.render();
//         //global.$("[data-carousel-3d]").Carousel3d();
//         //alert("fdh"+window.innerWidth);
//         //global.$('#screenshots-carousel3D')[0].innerHTML= this.getRender();
//         //this.setState=({ width: 5, height: window.innerHeight , carousel3D: <Carousel3D/>});
//         //global.$("[data-carousel-3d]").Carousel3d();
//       };

//     componentDidMount() {
//         global.window.addEventListener('resize', this.updateDimensions);
//         global.document.addEventListener('resize', this.updateDimensions);
//     }
//     componentWillUnmount() {
//         global.window.removeEventListener('resize', this.updateDimensions);
//         global.document.removeEventListener('resize', this.updateDimensions);
//     }

}

export default Screenshot;










